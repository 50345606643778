import classes from "./SearchFormInput.module.css";
import { Fragment, useRef } from "react";
import clearInput from "src/assets/img/clearInputIcon.png";

export function MobileSearchFormInput({
  id,
  value,
  label,
  placeholder,
  onChangeInput,
  onClear,
}) {
  const inputRef = useRef();

  const onChangeHandler = (e) => {
    onChangeInput(e.target.value);
  };

  const onClearHandler = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    onClear();
  };

  return (
    <Fragment>
      <div id={"search-form-input-" + id} className={`${classes.frame} ${classes.active}`}>
        <label htmlFor={id} className={classes.bigText}>
          <span onClick={(e) => e.stopPropagation()}>{label}</span>
          <input
            ref={inputRef}
            id={id}
            type="text"
            value={value}
            placeholder={placeholder}
            autoComplete="off"
            onChange={onChangeHandler}
          />
        </label>
        {value && (
          <button
            type="button"
            aria-label="Clear"
            onClick={onClearHandler}
            className={classes.icon}>
            <img width={16} alt="" src={clearInput} />
          </button>
        )}
      </div>
    </Fragment>
  );
}
