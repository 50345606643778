import { IconInfoSmall } from "@tabler/icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { Interweave } from "interweave";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { STATIC_ELEMENT } from "src/constants/static";
import { useDeviceContext } from "src/context/device-context";
import { useTenantContext } from "src/context/tenant-context";
import useClickAway from "src/hooks/useClickAway";
import useStaticContent from "src/hooks/useStaticContent";
import "./Footer.css";

const NOTICE_TEXT = `SIA "LATVIA TRAVEL D" īsteno projektu Atveseļošanas un noturības mehānisma plāna 2. komponentes "Digitālā transformācija" 2.2. reformu un investīciju virziena "Uzņēmumu digitālā transformācija un inovācijas" 2.2.1.r. "Uzņēmējdarbības digitālās transformācijas pilna cikla atbalsta izveide ar reģionālo tvērumu" 2.2.1.2.i. investīcijas "Atbalsts procesu digitalizācijai komercdarbībā" programmas ietvaros, projekta līguma Nr. Nr.17.2-5-L-2024/277. Projekta ietvaros uzņēmums veica komercdarbības procesu uzlabošanu: pārdošanas procesi, proti, tiešsaistes lidojumu rezervācijas programmatūras iegāde.`;

const NOTICE_LOGOS = (
  <>
    <img alt="" src={require(`src/assets/img/images/logo1.webp`)} />
    <img alt="" src={require(`src/assets/img/images/logo2.webp`)} />
    <img alt="" src={require(`src/assets/img/images/logo3.webp`)} />
  </>
);

const variants = {
  open: { opacity: 1, scale: 1 },
  closed: { opacity: 0, scale: 0.25 },
};

function Notice() {
  const [doShow, setDoShow] = useState(false);
  const elRef = useRef(null);

  const hideNoticeHandler = useCallback(() => setDoShow(false), []);
  useClickAway(elRef, hideNoticeHandler);

  return (
    <button
      ref={elRef}
      onClick={() => setDoShow((current) => !current)}
      className="footer-notice">
      <IconInfoSmall />
      <AnimatePresence>
        {doShow && (
          <motion.div
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.3, ease: "backOut" }}
            className="footer-notice-text">
            <div className="inner">{NOTICE_TEXT}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
}

function MobileFooter({ template }) {
  return (
    <div style={{ display: "contents" }} className="footer-mobile">
      <Interweave
        content={template}
        transform={(node, children) => {
          if (node.classList.contains("footer-heading")) {
            return React.createElement(
              node.tagName.toLowerCase(),
              {
                className: "footer-heading collapsed",
                onClick: (e) => {
                  const willExpand = e.currentTarget.classList.contains("collapsed");
                  if (willExpand) {
                    const footer = document.querySelector("footer");
                    const otherHeadings = footer.querySelectorAll(".footer-heading");
                    otherHeadings.forEach((el) => el.classList.add("collapsed"));
                  }
                  e.currentTarget.classList.toggle("collapsed");
                },
              },
              children
            );
          }
        }}
      />
      {window.SERVER_DATA.REACT_APP_TENANT_NAME === "celotleti" && (
        <div className="footer-notice-logos">
          {NOTICE_LOGOS}
          <Notice />
        </div>
      )}
    </div>
  );
}

function Footer({ onLoad }) {
  const { isMobile } = useDeviceContext();
  const { footer } = useTenantContext();
  const { pathname } = useLocation();
  const { html: template } = useStaticContent(STATIC_ELEMENT.footer);

  const hideFooter = pathname === "/search/results" || !footer;

  useEffect(() => {
    if (!template) return;
    if (onLoad) onLoad("footer");
  }, [onLoad, template]);

  if (hideFooter) return null;
  if (isMobile) return <MobileFooter template={template} />;

  return (
    <>
      <div
        style={{ display: "contents" }}
        className={isMobile ? "footer-mobile" : ""}
        dangerouslySetInnerHTML={{ __html: template }}></div>
      {window.SERVER_DATA.REACT_APP_TENANT_NAME === "celotleti" && (
        <div className="footer-notice-logos desktop">
          {NOTICE_LOGOS}
          <Notice />
        </div>
      )}
    </>
  );
}

export default Footer;
