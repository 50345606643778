import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import TagManager from "react-gtm-module";

const tagManagerArgs = { gtmId: window.SERVER_DATA.REACT_APP_GTM_ID };
TagManager.initialize(tagManagerArgs);

// move tenant css to the last position in the head to be able to override styles
const tenantCSSLink = document.getElementById("tenant-css");
if (tenantCSSLink) {
  const head = document.getElementsByTagName("head")[0];
  tenantCSSLink.parentNode.removeChild(tenantCSSLink);
  head.appendChild(tenantCSSLink);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(console.log);
